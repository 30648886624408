import { useConfirmModal } from "@/composables/useConfirmModal";
import { useContract } from "@/composables/useContract";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { useApeTraitsContract } from "@/web3/contracts";

const abi = [
  {
    inputs: [
      { internalType: "uint256[]", name: "traitsIds", type: "uint256[]" },
      { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
    ],
    name: "burnTraits",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const contractAddress =
  Config.network.name === "sepolia"
    ? "0xC7842f16F84b6A029e10c43f7C7a66Ca0A005960"
    : "0x3b30FC8474c82C476232D730d53E13aC7dF0929C";
const useBurnTraitsContract = useContract(abi, contractAddress);
// [{id, count}]
export const moveTraitsOffChain = async (traits) => {
  try {
    const traitsIds = traits.map((trait) => trait.id);
    const amounts = traits.map((trait) => trait.count);
    console.log(traitsIds, amounts);
    if (!(await approveBurnTraits())) return;
    await useToastTransaction(
      useBurnTraitsContract(true).methods.burnTraits(traitsIds, amounts),
      { text: "Moving traits off-chain" }
    );
    return true;
  } catch (err) {
    captureError(err);
  }
};

async function approveBurnTraits() {
  try {
    const wallet = useWeb3Store().walletAddress;
    const approveAddress = contractAddress;
    const isApproved = await useApeTraitsContract()
      .methods.isApprovedForAll(wallet, approveAddress)
      .call();
    if (isApproved) return true;
    const confirmed = await useConfirmModal({
      title: "Contract needs your approval",
      text: `The contract requires your approval to use your Ape traits tokens.
      <br><br>After you approve the contract, burning traits will continue.`,
    });
    if (!confirmed) return false;
    const approveForAllResponse = await useToastTransaction(
      useApeTraitsContract(true).methods.setApprovalForAll(
        approveAddress,
        true
      ),
      {
        text: "Approving Ape Traits Contract",
        successText: "You have successfully approved the Ape Traits Contract",
      }
    );
    return approveForAllResponse;
  } catch (err) {
    captureError(err);
  }
}
