import { useApesStorageApi } from "@/api/useApesStorageApi";
import { signMessage } from "@/helpers/sign-message";
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";
import { useToast } from "@/composables/useToast";
import { captureError } from "@/helpers/captureError";
export const traitMarketplaceUpdate = async ({ offerId, id, price, amount, isBuying, isUtility }) => {
  try {
    const message = !isBuying    
      ? "I'm updating a listing"
      : "I'm updating an offer"
    const signature = await signMessage(message);
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/trading/update",
      method: "PATCH",
      data: {
        wallet: useWeb3Store().collectionWallet, // selling the trait
        offerId,
        isUtility,
        price, // price per unit
        amount, // how many (final price = price * amount)
        signature,
        buyOrder: isBuying, // true if placing buy order (buying the trait), false if placing sell order (selling the trait)
        network: Config.network.name,
      },
    });
    useToast().success({
      title: `Trait #${id} was successfully updated`,
    });
    return res;
  } catch (err) {
    captureError(err);
  }
};
