<template>
    <AModalContent class="sell-trait-modal" @close="$emit('close')" :show-close="true">
        <div class="sell-trait-modal-title">
            {{ `${!buyOrder ? "List for sale trait" : "Submit offer for trait"}`}}
            <span>{{ trait.name }}</span>
        </div>
        <form class="sell-trait-modal-form" action="#" @submit.prevent="onSubmit">
            <label class="sell-trait-modal-input-label" for="sell-trait-price">Price per trait (METH)</label>
            <div class="sell-trait-modal-input-block">
                <input id="sell-trait-price" type="number"
                    class="app-input sell-trait-modal-input sell-trait-modal-input-price" v-model.number="price">
                <div class="sell-trait-modal-input-icon">
                    <img src="@/assets/img/meth.png" alt="meth">
                </div>
            </div>
            <label class="sell-trait-modal-input-label" for="sell-trait-amount">Count</label>
            <div class="sell-trait-modal-input-block">
                <input id="sell-trait-amount" type="number" class="app-input sell-trait-modal-input"
                    v-model.number="amount">
            </div>
            <div class="sell-trait-modal-info">
                <div class="sell-trait-modal-info-title">You will {{ `${!buyOrder ? "receive" : "pay"}` }}</div>
                {{ priceText }}
            </div>
            <button class="sell-trait-modal-button" type="submit" :class="{ disabled: isLoading }"> {{ `${buyOrder ? "Submit Offer" :"List Item"}` }}</button>
            <div v-if="!buyOrder" class="sell-trait-modal-tooltip">
                Listing on-chain traits will first move them off-chain
            </div>
        </form>
    </AModalContent>
</template>
<script setup>
import { traitMarketplaceSell } from "@/functions/trait-marketplace/trait-marketplace-sell";
import { traitMarketplaceUpdate } from "@/functions/trait-marketplace/trait-marketplace-update";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, ref, watch } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";
import IEthereum from "@/assets/icons/ethereum.svg?inline"
import { useToast } from "@/composables/useToast";
const props = defineProps({ id: Number, isUtility: Boolean, offerId: String, price: Number, amount: Number, isBuying: Boolean, traitName: String, onSuccess: Function })
const emit = defineEmits(['close'])

const price = ref(props.price || 0)
const amount = ref(props.amount || 1)
const isLoading = ref(false)
const taxValue = 0.015
const tax = computed(() => Math.floor(price.value * amount.value * taxValue))
const trait = computed(() => useWalletCollectionStore().apeTraits.find(trait => trait.id == props.id) || {name:props.traitName, balance:0, offChain:999})
const buyOrder = computed(() => props.isBuying)
const onSubmit = async () => {
    if (!price.value || price.value < 1) {
        useToast().error({ title: "Please enter a valid price" })
        return
    }
    if (!amount.value || amount.value < 1) {
        useToast().error({ title: "Please enter a valid count" })
        return
    }
    isLoading.value = true
    let success
    if (props.offerId) {
        success = await traitMarketplaceUpdate({ isUtility: props.isUtility, id: props.id, offerId: props.offerId, amount: amount.value, price: price.value, isBuying: props.isBuying })
        
    } else {
        success = await traitMarketplaceSell({ isUtility: props.isUtility, id: props.id, amount: amount.value, price: price.value, isBuying: props.isBuying })
        
    }
    if (success) {
        props.onSuccess && props.onSuccess()
        emit('close')
    }
    isLoading.value = false
}
const totalBalance = computed(() => trait.value.balance + trait.value.offChain)
const totalPrice = computed(() => !props.isBuying ? amount.value * price.value - tax.value : amount.value * price.value + tax.value)
const priceText = computed(() => `${ amount.value } x  ${ price.value } METH ${!props.isBuying ? "-" : "+"} ${ tax.value } METH tax (1.5%) = ${ totalPrice.value } METH`)

watch(amount, () => {
    if (!isNaN(parseInt(amount.value)) && amount.value < 1) {
        amount.value = 1
    }
    if (amount.value > totalBalance.value) {
        amount.value = totalBalance.value
    }
    if (amount.value) {
        amount.value = Math.round(amount.value)
    }
})
watch(price, () => {
    if (!isNaN(parseInt(price.value)) && price.value < 1) {
        price.value = 1
    }
})
</script>
<style lang="scss">
.sell-trait-modal {
    font-weight: bold;
    width: 500px;
    max-width: 90%;
    z-index: 10;
    background-color: var(--gray-light);
    border-radius: 10px;
    padding: 20px;

    &-title {
        font-size: 24px;
        margin-bottom: 20px;

        span {
            color: var(--primary);
        }
    }

    &-tooltip {
        font-size: 14px;
        opacity: 0.5;
        text-align: center;
        margin-top: 5px;
    }

    &-input {
        padding: 10px 10px;

        &-block {
            position: relative;
            margin-bottom: 15px;
        }

        &-price {
            padding-left: 45px;
        }

        &-icon {
            position: absolute;
            left: 8px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 8px;
            border-right: 1px solid var(--border);
            box-sizing: border-box;

            img {
                width: 20px;
            }
        }

        &-label {
            text-transform: uppercase;
            font-size: 14px;
            opacity: 0.5;
            margin-bottom: 5px;
            display: block;
        }
    }

    &-info {
        &-title {
            opacity: 0.5;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    &-button {
        background-color: var(--primary);
        width: 100%;
        display: block;
        border-radius: 5px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        img {
            margin-right: 5px;
        }

        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>