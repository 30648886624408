import { useApesStorageApi } from "@/api/useApesStorageApi";
import { signMessage } from "@/helpers/sign-message";
import { Config } from "@/config";
import { useWeb3Store } from "@/store/web3";
import { useToast } from "@/composables/useToast";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { moveTraitsOffChain } from "@/functions/moveTraitsOffChain";
export const traitMarketplaceSell = async ({
  id,
  price,
  amount,
  isBuying,
  isUtility,
}) => {
  try {
    if (!isBuying && !isUtility) {
      const trait = useWalletCollectionStore().apeTraits.find(
        (trait) => trait.id == id
      );
      if (!trait) return;
      if (amount > trait.offChain) {
        const done = await moveTraitsOffChain([
          { id, count: amount - trait.offChain },
        ]);
        if (!done) return;
      }
    }

    const message = !isBuying
      ? "I'm listing an item"
      : "I'm submitting an offer"
    const signature = await signMessage(
      message
    );
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/trading/create",
      method: "POST",
      data: {
        wallet: useWeb3Store().collectionWallet, // selling the trait
        isUtility,
        id, // of the trait
        price, // price per unit
        amount, // how many (final price = price * amount)
        signature,
        buyOrder: isBuying, // true if placing buy order (buying the trait), false if placing sell order (selling the trait)
        network: Config.network.name,
      },
    });
    useToast().success({
      title: `${!isBuying ? "Sell" : "Buy"} order for ${
        isUtility ? "utility" : "trait"
      } #${id} was successfully listed.`,
    });
    return res;
  } catch (err) {
    captureError(err);
  }
};
